'use client'

import React, { useState } from 'react'
import { QueryClientProvider as ReactQueryClientProvider, QueryClient } from '@tanstack/react-query'

const QueryClientProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    const [queryClient] = useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                    },
                },
            })
    )
    return <ReactQueryClientProvider client={queryClient}>{children}</ReactQueryClientProvider>
}

export default QueryClientProvider
