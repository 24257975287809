'use client'

import React from 'react'
import { LocalizationProvider as MUILocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const LocalizationProvider = ({ children }: { children: React.ReactNode }): JSX.Element => (
    <MUILocalizationProvider dateAdapter={AdapterDayjs}>
        <>{children}</>
    </MUILocalizationProvider>
)

export default LocalizationProvider
