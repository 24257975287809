import React from 'react'
import useScrollTrigger from '@mui/material/useScrollTrigger'

type ElevateOnScrollProps = {
    children: React.ReactElement
    elevationHeight: number
}

const ElevateOnScroll = ({ children, elevationHeight }: ElevateOnScrollProps): JSX.Element | null => {
    const trigger = useScrollTrigger()

    return React.cloneElement(children, {
        elevation: trigger ? elevationHeight : 0,
    })
}

export default ElevateOnScroll
