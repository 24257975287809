'use client'

import type { Dispatch, SetStateAction } from 'react'
import React, { createContext, useContext, useEffect, useState } from 'react'

// https://github.com/vercel/next.js/discussions/41934#discussioncomment-8996669
const NavigationBlockerContext = createContext<[isBlocked: boolean, setIsBlocked: Dispatch<SetStateAction<boolean>>]>([
    false,
    () => {},
])

export const NavigationBlockerProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    // [isBlocked, setBlocked]
    const state = useState(false)

    return <NavigationBlockerContext.Provider value={state}>{children}</NavigationBlockerContext.Provider>
}

export const useIsBlocked = (): boolean => {
    const [isBlocked] = useContext(NavigationBlockerContext)
    return isBlocked
}

export const Blocker = (): null => {
    const [isBlocked, setBlocked] = useContext(NavigationBlockerContext)
    useEffect(() => {
        setBlocked(() => true)
        return () => {
            setBlocked(() => false)
        }
    }, [isBlocked, setBlocked])

    return null
}

export const BlockBrowserNavigation = (): null => {
    const isBlocked = useIsBlocked()
    useEffect(() => {
        if (isBlocked) {
            const showModal = (event: BeforeUnloadEvent) => {
                event.preventDefault()
            }

            window.addEventListener('beforeunload', showModal)
            return () => {
                window.removeEventListener('beforeunload', showModal)
            }
        }
        return () => {}
    }, [isBlocked])

    return null
}
