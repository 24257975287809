'use client'

import React from 'react'
import { AppProgressBar } from 'next-nprogress-bar'
import './ProgressBar.css'

const ProgressBarProvider = ({ children }: { children: React.ReactNode }): JSX.Element => (
    <>
        {children}
        <AppProgressBar height="2px" color="#0288d1" options={{ showSpinner: false }} shallowRouting />
    </>
)

export default ProgressBarProvider
