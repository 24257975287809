/* eslint-disable react/prop-types, react/require-default-props */
import type { LinkProps as NextLinkProps } from 'next/link'
import type { LinkProps as MuiLinkProps } from '@mui/material/Link'
import * as React from 'react'
import clsx from 'clsx'
import { usePathname } from 'next/navigation'
import NextLink from 'next/link'
import MuiLink from '@mui/material/Link'
import { startTransition } from 'react'
import { resolveHref } from 'next/dist/client/resolve-href'
import Router from 'next/router'
import { useRouter } from 'next-nprogress-bar'
import { useIsBlocked } from './NavigationBlock'
import { unsavedChangesMessage } from '@/lib/utils/form'

interface NextLinkComposedProps
    extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'>,
        Omit<NextLinkProps, 'href' | 'as'> {
    to: NextLinkProps['href']
    linkAs?: NextLinkProps['as']
    href?: NextLinkProps['href']
}

export const NextLinkComposed = React.forwardRef<HTMLAnchorElement, NextLinkComposedProps>((props, ref) => {
    const { to, linkAs, href, replace, scroll, shallow, prefetch, locale, ...other } = props

    const router = useRouter()
    const [, url] = resolveHref(Router, to, true)
    const isBlocked = useIsBlocked()

    return (
        <NextLink
            href={to}
            prefetch={prefetch}
            as={linkAs}
            replace={replace}
            scroll={scroll}
            shallow={shallow}
            passHref
            locale={locale}
            ref={ref}
            onClick={(e) => {
                // Check if the link is intended to open in a new tab
                if (e.currentTarget.target === '_blank') {
                    return // Do nothing, allowing the browser to handle it natively
                }
                e.preventDefault()
                // eslint-disable-next-line no-alert
                if (isBlocked && !window.confirm(unsavedChangesMessage)) {
                    return
                }
                startTransition(() => {
                    if (url) {
                        if (replace) {
                            router.replace(url)
                        } else {
                            router.push(url)
                        }
                    }
                })
            }}
            {...other}
        />
    )
})

export type LinkProps = {
    activeClassName?: string
    as?: NextLinkProps['as']
    href: NextLinkProps['href']
    noLinkStyle?: boolean
} & Omit<NextLinkComposedProps, 'to' | 'linkAs' | 'href'> &
    Omit<MuiLinkProps, 'href'>

// A styled version of the Next.js Link component:
// https://nextjs.org/docs/#with-link
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
    const {
        activeClassName = 'active',
        as: linkAs,
        className: classNameProps,
        href,
        noLinkStyle,
        role, // Link don't have roles.
        sx,
        ...other
    } = props

    const routerPathname = usePathname()
    const pathname = typeof href === 'string' ? href : href.pathname
    const className = clsx(classNameProps, {
        [activeClassName]: routerPathname === pathname && activeClassName,
    })
    const styles = {
        color: 'info.main',
        textDecoration: 'none',
        '&:hover': {
            color: 'info.dark',
        },
        '@media print': { color: 'text.primary' },
        ...sx,
    }

    const isExternal = typeof href === 'string' && (href.startsWith('http') || href.startsWith('mailto:'))

    if (isExternal) {
        return <MuiLink className={className} href={href} ref={ref} sx={styles} {...other} />
    }

    if (noLinkStyle) {
        return <NextLinkComposed className={className} ref={ref} to={href} {...other} />
    }

    return (
        <MuiLink
            component={NextLinkComposed}
            linkAs={linkAs}
            className={className}
            ref={ref}
            to={href}
            sx={styles}
            {...other}
        />
    )
})

export default Link
