'use client'

import React, { useState } from 'react'
import Image from 'next/image'
import { Box, Tooltip } from '@mui/material'
import { useWatchVariable } from '@/lib/hooks'

type TeamLogoProps = {
    url: string | null | undefined
    name: string | null
    height?: number
    width?: number
    fontSize?: number
    placement?:
        | 'left'
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top'
        | undefined
    opacity?: number
}

const TeamLogo = ({
    url,
    name,
    height,
    width,
    fontSize,
    placement = 'left',
    opacity = 1,
}: TeamLogoProps): JSX.Element => {
    const [imgSrc, setImgSrc] = useState<string>(url || '')
    useWatchVariable((urlProp) => {
        if (urlProp !== imgSrc) setImgSrc(urlProp || '')
    }, url)

    return imgSrc ? (
        <Tooltip placement={placement} title={name || ''}>
            <Box
                sx={{
                    display: 'table-cell',
                    position: 'relative',
                    height: height || 28,
                    minWidth: url ? width || 28 : 0,
                    textAlign: 'center',
                    paddingX: '15px',
                    opacity,
                }}
            >
                <Image
                    alt="team logo"
                    src={imgSrc}
                    fill
                    sizes="100%"
                    onError={() => setImgSrc('')}
                    style={{
                        objectFit: 'contain',
                    }}
                />
            </Box>
        </Tooltip>
    ) : (
        <Box sx={{ textAlign: 'left', fontSize, marginLeft: fontSize ? 0.75 : undefined }}>{name}</Box>
    )
}

export default TeamLogo
