'use client'

import type { ImageProps } from 'next/image'
import type { Except } from 'type-fest'
import type { ResponsiveStyleValue, SystemCssProperties } from '@mui/system'
import React, { useMemo, useState } from 'react'
import Image from 'next/image'
import Avatar from '@mui/material/Avatar'
import { useGetAttachment, useWatchVariable } from '@/lib/hooks'

type ImageWithFallbackProps = Except<ImageProps, 'src'> & {
    src: string | null | undefined
    fallbackSize?: ResponsiveStyleValue<number>
    outlined?: SystemCssProperties
    children?: JSX.Element
    zIndex?: number
}

const ImageWithFallback = ({
    src,
    fallbackSize,
    outlined,
    alt,
    zIndex,
    children: fallbackIcon,
    ...rest
}: ImageWithFallbackProps): JSX.Element => {
    const isManualOverrideHeadshot = src?.split('/')[1] === 'api'
    const attachmentId = useMemo<string | null>(
        () => (isManualOverrideHeadshot && src.split('/')[3]) || null,
        [src, isManualOverrideHeadshot]
    )
    const { data: attachment, isLoading: isAttachmentLoading } = useGetAttachment(attachmentId)

    const [imgSrc, setImgSrc] = useState<string>((isManualOverrideHeadshot ? attachment?.url : src) || '')

    useWatchVariable(
        ([attachmentUrl, srcProp, srcState, isOverride, isLoading]) => {
            if (attachmentUrl) {
                setImgSrc(attachmentUrl)
            } else if (isOverride && (isLoading || !attachment)) {
                setImgSrc('')
            } else if (srcProp !== srcState) {
                setImgSrc(srcProp || '')
            }
        },
        [attachment?.url, src, imgSrc, isManualOverrideHeadshot, isAttachmentLoading] as const
    )

    return !imgSrc ? (
        <Avatar
            alt="empty image"
            sx={{ width: fallbackSize, height: fallbackSize, '> svg': { width: '75%', height: '75%' }, ...outlined }}
        >
            {fallbackIcon}
        </Avatar>
    ) : (
        <Image
            style={{
                objectFit: 'contain',
                zIndex,
            }}
            {...rest}
            alt={alt}
            src={imgSrc}
            fill={!rest.width}
            onError={() => setImgSrc('')}
        />
    )
}

export default ImageWithFallback
