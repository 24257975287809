'use client'

import React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import useToastContext from '../lib/hooks/useToastContext'

const Toast = (): JSX.Element | null => {
    const toastContext = useToastContext()

    if (!toastContext?.toast) return null

    const { toast, removeToast } = toastContext

    return (
        <Snackbar open autoHideDuration={toast.duration || 6000} onClose={() => removeToast()}>
            <Alert
                elevation={6}
                variant={toast.severity !== 'success' ? 'filled' : 'standard'}
                severity={toast.severity}
                sx={{ width: '100%' }}
            >
                {toast.message}
            </Alert>
        </Snackbar>
    )
}

export default Toast
