import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/IdleTimer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/layout/AppBar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationBlockerProvider"] */ "/app/components/NavigationBlock.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/components/Toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ConstantsContextProvider"] */ "/app/lib/contexts/ConstantsContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ToastContextProvider"] */ "/app/lib/contexts/ToastContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/lib/providers/AuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/lib/providers/LocalizationProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/lib/providers/ProgressBarProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/lib/providers/QueryClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/lib/providers/SessionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/lib/ThemeRegistry/ThemeRegistry.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ReactQueryDevtools"] */ "/app/node_modules/@tanstack/react-query-devtools/build/lib/index.mjs");
