import { createTheme } from '@mui/material/styles'
import { green, red, indigo, teal, orange } from '@mui/material/colors'
import { Roboto } from 'next/font/google'

const roboto = Roboto({
    weight: ['300', '400', '500', '700'],
    subsets: ['latin'],
    display: 'swap',
})

// Create a theme instance.
const theme = createTheme({
    breakpoints: {
        // https://mui.com/material-ui/customization/breakpoints/#default-breakpoints
        values: {
            xs: 0,
            sm: 600,
            print: 800,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    typography: {
        fontFamily: roboto.style.fontFamily,
        h5: {
            fontWeight: 'bold',
        },
    },
    palette: {
        background: {
            default: 'rgb(250, 250, 250)',
        },
        callOut: {
            alert: '#fecdd2',
            info: '#e3f2fd',
            success: '#d2fecd',
        },
        legend: {
            top90: green[300],
            top80: green[200],
            top70: green[100],
            top60: green[50],
            bot40: red[50],
            bot30: red[100],
            bot20: red[200],
            bot10: red[300],
        },
        yellow: {
            main: '#fdd835',
        },
        post: {
            intel: teal[400],
            scouting: indigo[300],
            playerDevelopment: orange[700],
            community: red[600],
            comment: '#0288d1',
            workout: '#ff9800',
        },
        injury: {
            dtd: '#fdd835',
            out7: '#ffcccc',
            out30: '#ff0000',
            ofs: '#b30000',
        },
        westchester: {
            blue: '#003da6',
        },
        knicks: {
            blue: '#006bb6',
            orange: '#f58426',
            grey: '#bec0c2',
            black: '#000000',
        },
    },
    appHeaders: {
        appAndEntityHeaderMobile: 116,
        appAndEntityHeader: 148,
        appAndEntityHeaderMobileExpanded: 217,
        appAndEntityHeaderNotMobileExpanded: 233,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '@media print': {
                    '@page': {
                        // 216 x 279 mm (letter) scaled 55%
                        // with margin, this is equivalent to "md" breakpoint
                        size: '335mm 432.5mm',
                        margin: '16mm',
                    },
                    '.MuiContainer-root': {
                        paddingLeft: '0 !important',
                        paddingRight: '0 !important',
                    },
                    'header .MuiToolbar-root': {
                        paddingLeft: '0 !important',
                        paddingRight: '0 !important',
                    },
                },
            },
        },
    },
})

export default theme
