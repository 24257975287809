'use client'

import React from 'react'
import { SessionProvider as NextAuthProvider } from 'next-auth/react'

type Props = {
    children?: React.ReactNode
}
const SessionProvider = ({ children }: Props): JSX.Element => <NextAuthProvider>{children}</NextAuthProvider>

export default SessionProvider
